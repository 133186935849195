import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index"
Vue.use(VueRouter)

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


const routes = [
    { path: '/404', component: () => import('@/views/404'), hidden: true },
    { path: '*', redirect: '/404', hidden: true },
    {
        path: '/',
        redirect: to => {
            return 'login'   //返回路径'login'
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Login - 登录',
            hideInMenu: true
        },
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: 'home - 主页',
            hideInMenu: true
        },
        component: () => import('@/views/home/home.vue'),
        redirect: '/hometown',
        children: [{
            path: '/hometown',
            name: 'business/hometown',
            component: () => import('@/views/business/hometown/hometown.vue'),
            meta: { title: '首页', icon: 'home' }
        }, {
            path: '/businessStatistics',
            name: 'business/businessStatistics',
            component: () => import('@/views/business/businessStatistics/businessStatistics.vue'),
            meta: { title: '首页', icon: 'home' }
        }, {
            path: '/commodityStatistics',
            name: 'business/commodityStatistics',
            component: () => import('@/views/business/commodityStatistics/commodityStatistics.vue'),
            meta: { title: '首页', icon: 'home' }
        }, {
            path: '/userStatistics',
            name: 'business/userStatistics',
            component: () => import('@/views/business/userStatistics/userStatistics.vue'),
            meta: { title: '首页', icon: 'home' }
        },
        {
            path: '/productList',
            name: 'product/productList',
            component: () => import('@/views/commodity/productList/productList.vue'),
            meta: { title: '产品列表', icon: 'home' }
        },
        {
            path: '/imageList',
            name: 'product/imageList',
            component: () => import('@/views/commodity/imageList/imageList.vue'),
            meta: { title: '图片列表', icon: 'home' }
        },
        {
            path: '/productClassify',
            name: 'product/productClassify',
            component: () => import('@/views/commodity/productClassify/productClassify.vue'),
        },
        {
            path: '/productCategory',
            name: 'product/productCategory',
            component: () => import('@/views/commodity/productCategory/productCategory.vue'),
        },
        {
            path: '/productSpecifications',
            name: 'product/productSpecifications',
            component: () => import('@/views/commodity/productSpecifications/productSpecifications.vue'),
        },
        {
            path: '/productAttribute',
            name: 'product/productAttribute',
            component: () => import('@/views/commodity/productAttribute/productAttribute.vue'),
        },
        // 产品封装
        {
            path: '/productPackaging',
            name: 'product/productPackaging',
            component: () => import('@/views/commodity/productPackaging/productPackaging.vue'),
        },
        // 利润率
        {
            path: '/profitMargin',
            name: 'product/profitMargin',
            component: () => import('@/views/commodity/profitMargin.vue'),
        },
        // 活动列表
        {
            path: '/activityList',
            name: 'product/activityList',
            component: () => import('@/views/commodity/activityList.vue'),
        },
        {
            path: '/ArticleList',
            name: 'blog/ArticleList',
            component: () => import('@/views/blog/ArticleList/ArticleList.vue'),
        },
        {
            path: '/ArticleClassify',
            name: 'blog/ArticleList',
            component: () => import('@/views/blog/ArticleClassify/ArticleClassify.vue'),
        },
        // 用户列表
        {
            path: '/userList',
            name: 'user/userList',
            component: () => import('@/views/user/userList/userList.vue'),
        },
        // 订单列表
        {
            path: '/orderList',
            name: 'order/orderList',
            component: () => import('@/views/order/orderList.vue'),
        },
        // 系统设置
        {
            path: '/systemSettings',
            name: 'setup/systemSettings',
            component: () => import('@/views/setup/systemSettings.vue'),
        },
        // banner
        {
            path: '/banner',
            name: 'setup/banner',
            component: () => import('@/views/setup/banner.vue'),
        },
        // 物流模板
        {
            path: '/LogisticsTemplate',
            name: 'setup/LogisticsTemplate',
            component: () => import('@/views/setup/LogisticsTemplate.vue'),
        },
        // 运费模板
        {
            path: '/FreightTemplate',
            name: 'setup/FreightTemplate',
            component: () => import('@/views/setup/FreightTemplate.vue'),
        },
        // 客户列表
        {
            path: '/customerList',
            name: 'user/customerList',
            component: () => import('@/views/user/customerList.vue'),
        },
        // 我的客户
        {
            path: '/myCustomer',
            name: 'user/myCustomer',
            component: () => import('@/views/user/myCustomer.vue'),
        },
        // 公海客户
        {
            path: '/seasCustomer',
            name: 'user/seasCustomer',
            component: () => import('@/views/user/seasCustomer.vue'),
        },
        // 询价列表
        {
            path: '/enquiryList',
            name: 'order/enquiryList',
            component: () => import('@/views/order/enquiryList.vue'),
        },
        // 需求列表
        {
            path: '/demandList',
            name: 'order/demandList',
            component: () => import('@/views/order/demandList.vue'),
        },
        // BOM表列表
        {
            path: '/BOMlist',
            name: 'order/BOMlist',
            component: () => import('@/views/order/BOMlist.vue'),
        },
        // 邮件订阅列表
        {
            path: '/email',
            name: 'user/email',
            component: () => import('@/views/user/email.vue'),
        },
        // 反馈列表
        {
            path: '/feedbackList',
            name: 'user/feedbackList',
            component: () => import('@/views/user/feedbackList.vue'),
        },
        // 用户中心
        {
            path: '/userInfo',
            name: 'user/userInfo',
            component: () => import('@/views/user/userInfo.vue'),
        },
        // 管理员列表
        {
            path: '/adminList',
            name: 'setup/adminList',
            component: () => import('@/views/setup/adminList.vue'),
        },
        // 管理员列表
        {
            path: '/RoleManage',
            name: 'setup/RoleManage',
            component: () => import('@/views/setup/RoleManage.vue'),
        },
        // 权限列表
        {
            path: '/permissionList',
            name: 'setup/permissionList',
            component: () => import('@/views/setup/permissionList.vue'),
        },
        {
            path: '/cacheManage',
            name: 'setup/cacheManage',
            component: () => import('@/views/setup/CacheManage.vue'),
        },
        // 素材中心
        {
            path: '/MaterialCenter',
            name: 'MaterialCenter/MaterialCenter',
            component: () => import('@/views/setup/MaterialCenter.vue'),
        },
        // demo
        {
            path: '/demo',
            name: 'demo',
            component: () => import('@/views/demo.vue'),
        },
        // demo
        {
            path: '/demo2',
            name: 'demo',
            component: () => import('@/views/demo2.vue'),
        },
        // 重定向到第一个页面
        {
            path: '/business',
            name: 'business/hometown',
            redirect: '/hometown',
            meta: { title: '首页', icon: 'home' }
        },
        {
            path: '/product',
            name: 'product',
            redirect: to => {
                console.log(store, 77777)
                const obj = store.state.menuList.filter((item) => {
                    return item.path === 'product'
                })
                console.log(obj[0].chilren[0].chilren[0].path, 'jieguo0')
                return ('/' + obj[0].chilren[0].chilren[0].path)
            },
            meta: { title: '首页', icon: 'home' }
        },
        {
            path: '/blog',
            name: 'blog/ArticleList',
            redirect: '/ArticleList',
            meta: { title: '首页', icon: 'home' }
        },
        {
            path: '/user',
            name: 'user/userList',
            redirect: '/userList',
            meta: { title: '首页', icon: 'home' }
        },
        {
            path: '/order',
            name: 'order/orderList',
            redirect: '/orderList',
            meta: { title: '订单列表', icon: 'home' }
        },
        {
            path: '/setup',
            name: 'setup/systemSettings',
            redirect: '/systemSettings',
            meta: { title: '订单列表', icon: 'home' }
        },
            // 重定向到第一个页面结束
        ]
    }
]


const router = new VueRouter({
    routes
})
// 路由拦截,防止直接输入路由
router.beforeEach((to, from, next) => {
    let token = store.state.token
    if (token && to.name == 'login') {
        next('/home')
    } else {
        if (!token && to.path !== '/login') {
            next('/login')
        } else {
            next()
        }
    }
})
router.afterEach((to, from) => {
    console.log(to)
    if (to.path !== '/login' && to.path !== '/productList') {
        store.dispatch('getView')
    }
})
export default router
